import { PipeTransform } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import * as dayjs from 'dayjs';
import { Observable, of, map, catchError } from "rxjs";
import { IComLibsServicesProjectCampaignV3Service } from "../services/projects/campaign/campaign_v3.service";

export const formatDatePipe = (): PipeTransform => {
  return {
    transform(time: string) {
      if (!time) {
        return '-';
      }
      return dayjs(time).format('DD/MM/YYYY HH:mm');
    },
  }
}

export const defaultValuePipe = (): PipeTransform => {
  return {
    transform(value: string) {
      if (!value) {
        return '-';
      }
      return value;
    },
  }
}

export const convertKeysToLowerCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((item: any) => convertKeysToLowerCase(item));
  } else if (typeof data === 'object' && data !== null) {
    const lowercaseData: any = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
        lowercaseData[newKey] = convertKeysToLowerCase(data[key]);
      }
    }
    return lowercaseData;
  } else {
    return data;
  }
}

export const parseDateRangeToTwoKey = (data: any, keys?: string, format?: string) => {
  let keysParse = ['start_time', 'end_time'];
  if (keys) {
    keysParse = keys.split(',');
  }
  const [keyStart, keyEnd] = keysParse;
  if (!data.date_range || !data.date_range.length) {
    return;
  }
  const dataRange = data.date_range;
  const [start, end] = dataRange;
  if (start instanceof String && end instanceof String) {
    return;
  }

  const obj: any = {
    [keyStart]: dayjs(start),
    [keyEnd]: dayjs(end)
  };
  if(format) {
    data[keyStart] = dayjs(start).format(format);
    data[keyEnd] = dayjs(end).format(format);
  } else {
    data[keyStart] = obj[keyStart];
    data[keyEnd] = obj[keyEnd];
  }
  delete data.date_range;
}

export const parseDateRangeDayMonthYearToTwoKey = (data: any, keys?: string, format?: string) => {
  let keysParse = ['start_time', 'end_time'];
  if (keys) {
    keysParse = keys.split(',');
  }
  const [keyStart, keyEnd] = keysParse;
  if (!data.date_range || !data.date_range.length) {
    return;
  }
  const dataRange = data.date_range;
  const [start, end] = dataRange;
  if (start instanceof String && end instanceof String) {
    return;
  }

  const obj = {
    [keyStart]: dayjs(start).format('YYYY-MM-DDT00:00:00') + 'Z',
    [keyEnd]: dayjs(end).format('YYYY-MM-DDT00:00:00') + 'Z'
  };
  if(format) {
    obj[keyStart] = dayjs(start).format(format);
    obj[keyEnd] = dayjs(end).format(format);
  }
  data[keyStart] = obj[keyStart];
  data[keyEnd] = obj[keyEnd];
  delete data.date_range;
}


export const formatNumber = (value: string | number): string => {

  // if (lang === LANGUAGE.EN) {
  //   return `${value}`.replace(/[,]/g, '');
  // }

  return `${value}`.replace(/[.]/g, '').replace(/[,]/g, '.');
};

export const mergeObjects = (obj1: any, obj2: any) => {
  for (const key in obj1) {
    // if (obj2.hasOwnProperty(key)) {
    //   if (obj1.hasOwnProperty(key)) {
    obj2[key] = obj1[key];
    // } else {
    //   obj1[key] = obj2[key];
    // }
    // }
  }
}

export const markFormGroupTouched = (formGroup: FormGroup) => {
  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    control.markAsDirty();
    control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    if (control instanceof FormGroup) {
      markFormGroupTouched(control);
    } else if (control instanceof FormArray) {
      (control as any).controls.forEach((c: any) => {
        if (c instanceof FormGroup) {
          markFormGroupTouched(c);
        } else if (c instanceof FormControl) {
          c.markAsTouched();
          c.markAsDirty();
          c.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        }
      });
    }
  });
}

export const checkFormValidity = (formGroup: FormGroup | FormArray): boolean => {
  if (formGroup instanceof FormGroup || formGroup instanceof FormArray) {
    for (const controlName in formGroup.controls) {
      const control = (formGroup.controls  as any)[controlName];
      if (control instanceof FormGroup || control instanceof FormArray) {
        if (control.errors && control instanceof FormArray) {
          return false;
        }
        if (!checkFormValidity(control)) {
          return false;
        }
      } else if (control instanceof FormControl) {
        if (control.invalid || control.errors) {
          return false;
        }
      }
    }
  }
  return true;
}

export const uploadFile = (request: string, file_name: string, IComMessageService: any, data?: any, keepFileName?: boolean, ignoreParseTime?: boolean, callback?: any, method: string = "POST") : any => {
  const xhr = new XMLHttpRequest();
  xhr.open(method, request, true);
  xhr.responseType = 'blob';
  let token = JSON.parse(localStorage?.getItem('token') as any)?.value;
  while(token?.includes('"')){
    token = token?.replace('"', "");
 }
  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      const contentDisposition = xhr.getResponseHeader('Content-Disposition');
      let dateString = '';
      if(!keepFileName) {
        const currentDate = new Date();
        dateString = `${currentDate.getDate()}_${currentDate.getMonth() + 1}_${currentDate.getFullYear()}`;
      }
      let filename = `${file_name}${dateString ? `_${dateString}` : ''}`;

      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
      IComMessageService.showAlertMessageDone('Tải file thành công.');
      callback && callback();
    } else {
      IComMessageService.showAlertMessageError('Tải file thất bại.');
      callback && callback();
    }
  };

  xhr.onerror = function () {
    console.error('Request error');
  };

  xhr.setRequestHeader('Content-Type', 'application/json');
  data && ignoreParseTime && parseDateRangeDayMonthYearToTwoKey(data);
  xhr.send(JSON.stringify(data));
}


export function removeKeysWithSpecificPrefix(obj: any, prefix: string) {
  Object.keys(obj).forEach(key => {
      if (key.startsWith(prefix)) {
          delete obj[key];
      }
  });
  return obj;
}

export function uploadFileV2(item: any , IComLibsServicesProjectCampaignV3Service : IComLibsServicesProjectCampaignV3Service): Observable<{ key: string, url: string }> {
  if (!item.file) {
    return of({ key: item.key, url: null });
  }
  if (typeof item.file === 'string') {
    return of({ key: item.key, url: item.file });
  }
  const formData = new FormData();
  formData.append('image', item.file);
  item.validate && (formData.append('validate', item.validate));
  item.width && (formData.append('width', item.width));
  item.height && (formData.append('height', item.height));
  item.size && (formData.append('size', item.size));

  return IComLibsServicesProjectCampaignV3Service.uploadFile(formData).pipe(
    map((response: any) => ({ key: item.key, url: response.data.path })),
    catchError(error => {
      return of({ key: item.key, url: null });
    })
  );
}